.nav_mobile{
  width: 350px;
  overflow: hidden;
  opacity: 0;
  position: fixed;
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  border-right: 1px solid rgba(25, 25, 25, 0.2);
  padding: 1rem;
//   left: -5rem;
right: -5rem;
  transition: .5s ease 0s;
//   transition: left 0.5s ease, opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 2000;
  visibility: hidden;
  &.active{
      right: 0;
      opacity: 1;
      visibility: visible;
    //   transform: translateX(-100%);
  }
  ul{
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 15px;
      li{
          padding: 10px;
          text-transform: capitalize;
          cursor: pointer;
          user-select: none;
          color: #01C673;
          transition: .3s ease 0s;
          font-size: 110%;
          position: relative;
          &::after{
              content: '';
              position: absolute;
              bottom: 0px;
              height: 0;
              width: 3px;
              background: linear-gradient(90deg, #0796C4 0%, #01C673 100%);
              right: 0;
              transition: .3s ease 0s;
          }
          &.active {
              background: linear-gradient(90deg, #0796C4 0%, #01C673 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              &::after{
                  height: 100%;
              }
          }
      }
  }
}

@media screen and (max-width: 440px) {
  .nav_mobile{
      width: 300px;
  }
}